
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

// @import'~swiper/swiper';
@import 'swiper/swiper-bundle.css';

* {
    [direction="rtl"] &,
    [dir="rtl"] & {
        @import "../src/assets/css-rtl/rtl.scss";
        // @import "../src/assets/css-rtl/style.scss";
        @import "../src/assets/css-rtl/responsive.scss";
        @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
        @import url(https://db.onlinewebfonts.com/c/2e6ae3642c879522873a28ede1eaac69?family=Helvetica+Neue+W23+for+SKY+Reg);
    }
}

.MainHeaderWrapper_header_bg__CXa1e {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .5s ease-in-out;
    width: 100%;
    z-index: -1;
}
.MainHeaderWrapper_animate__CLDjY {
    animation: MainHeaderWrapper_zoomin__X66yk 4s ease-in;
}
.overflow {
    overflow: hidden;
}
.MainHeaderWrapper_header_bg__CXa1e:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(transparent, rgba(48, 48, 48, 1));
    opacity: .7;
    transition: .5s;
}
.title_white {
    color: #fff;
    font-size: 2.5rem !important;
    line-height: 1.5 !important;
    text-transform: capitalize;
    font-weight: 700;
    width: 70%
}

@media screen and (max-width: 426px) {
    .title_white {
        font-size: 1.8rem !important;
    }
}

html {
    scroll-behavior: smooth;
  }


  .custom-confirm-button-class {
    background-color: #613f93; /* Green background */
    color: white;             /* White text */
    border: none;             /* No border */
    padding: 5px 10px;       /* Padding */
    text-align: center;       /* Centered text */
    text-decoration: none;    /* No underline */
    display: inline-block;    /* Inline-block */
    font-size: 14px;          /* Font size */
    margin: 4px 2px;          /* Margin */
    cursor: pointer;          /* Pointer cursor on hover */
    border-radius: 8px;       /* Rounded corners */
  }

  /* styles.css or your component's CSS file */
.custom-popup-class , .custom-container-class{
    background-color: white; /* Dark background */

    border-radius: 24px !important;    /* Rounded corners */
    padding: 20px;         /* Padding */
  }
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
